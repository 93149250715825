
import Editor from "@tinymce/tinymce-vue";
import Vue from "vue";

export default Vue.extend({
    components: {
        Editor,
    },

    props: {
        value: String,
        height: {
            type: Number,
            default: 500,
        },
    },

    data() {
        return {
            tinymce: null as any,
        };
    },

    methods: {
        filePickerCallback(cb: (result: FileReader["result"]) => void) {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.onchange = () => {
                const reader = new FileReader();
                reader.onload = () => cb(reader.result);
                reader.readAsDataURL(input.files![0]);
            };
            input.click();
        },

        insertContent(content: string, editable: boolean, id?: string) {
            if (!this.tinymce) {
                return false;
            }

            this.tinymce.insertContent(
                `<div ${id ? `id="${id}"` : ""} contenteditable="${editable ? "true" : "false"}">${content}</div>`
            );
            this.tinymce.selection.collapse(false);

            return true;
        },

        setup(editor: any) {
            this.tinymce = editor;
        },
    },
});
